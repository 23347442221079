import React from 'react';

import NavBar from '../../components/NavBar';

const AccountPage = () => {

    return (
        <div>
            <NavBar />
        </div>
    );

}

export default AccountPage;