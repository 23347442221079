import React from 'react';
import { useState } from 'react';

import DatePicker from 'react-datepicker';

// Date and Time picker
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';

import './timeSlide.css';
import '../slideStyle.css';

const Time = (props) => {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div id='timeSlideContainer'>
                <DateTimePicker
                    variant='inline'
                    label='Start Date'
                    value={props.startDate}
                    onChange={props.handleStartDateChange}
                    disablePast
                    format='yyyy/MM/dd/ HH:mm' />
                <DateTimePicker
                    variant='inline'
                    label='End Date'
                    value={props.endDate}
                    onChange={props.handleEndDateChange}
                    disablePast
                    format='yyyy/MM/dd/ HH:mm' />
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default Time;