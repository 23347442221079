import React from 'react';

import '../slideStyle.css';

const NameAndFile = (props) => {
    return (
        <div className='formSlide'>
            <div className='formLine'>
                <input className='formTextField' type='text' name='title' placeholder="Card Title" value={props.title} onChange={props.handleTitleChange} required />
            </div>
            <div className='formLine'>
                <input className='formTextField' type='file' name='file' accept="image/x-png,image/gif,image/jpeg" onChange={props.handleFileChange} required />
            </div>
        </div>
    )
}

export default NameAndFile;