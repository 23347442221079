import React from 'react';

const ScreenPage = () => {
    return (
        <div>
            <h1>Screen</h1>
        </div>
    );
}

export default ScreenPage;
