const HOST = 'https://lcd-api.utscaacc.com';
const PORT = '';
const ROOT = HOST + "/api";

// Status Endpoints
export const CREATE_STATUS = ROOT + "/status";
export const GET_STATUS = (statusTitle => ROOT + '/status/' + statusTitle);
export const DELETE_STATUS = (statusTitle => ROOT + '/status/' + statusTitle);

// Card Endpoints
export const CREATE_CARD = (statusTitle => ROOT + '/status/' + statusTitle + '/card');
export const GET_CARDS = (statusTitle => ROOT + '/status/' + statusTitle + '/card');
export const REPLACE_CARDS = (statusTitle => ROOT + '/status/' + statusTitle + '/card');
export const DELETE_CARD = ((statusTitle, cardId) => ROOT + '/status/' + statusTitle + '/card/' + cardId);

// Image Endpoints
export const UPLOAD_IMAGE = ROOT + "/image";
export const GET_IMAGE = (imageId => ROOT + '/image/' + imageId);

