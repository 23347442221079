import React, { useState } from 'react';
import axios from 'axios';
import * as endpoints from '../../constants/endpoints';

import Modal from 'react-modal';
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';

import './cardForm.css';
import PictureIcon from '../../assets/icons/picture.svg';

// Form slides
import NameAndFile from './form-steps/NameAndFile';
import Time from './form-steps/Time';
import Preview from './form-steps/Preview';

const createCard = (title, startDate, endDate, file) => {

    // Add file to form-data
    const data = new FormData();
    data.append('file', file);

    // Upload the image to the database and get the image id
    axios.post(endpoints.UPLOAD_IMAGE, data, { headers: { 'content-type': 'multipart/form-data' } })
        .then(res => {
            let imageId = res.data.file.id;
            let currentTime = new Date();
            let status = startDate <= currentTime ? "LIVE" : "UPCOMING";
            console.log(status);
            // Use the image id to create a card
            axios.patch(endpoints.CREATE_CARD("live"), { title, startDate, endDate, imageId })
                .then(res => {
                    console.log(res);
                    alert("Created card succesfully");
                    window.location.reload();
                })
                .catch(err => {
                    console.log(err);
                    alert("Error creating card");
                });
        })
        .catch(err => {
            console.log(err);
            alert("Error uploading image");
        });
}

const CardForm = (props) => {

    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [file, setFile] = useState("");

    const [activeStep, setActiveStep] = useState(0);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        createCard(title, startDate, endDate, file);
        console.log("created card");
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    }

    const onModalClose = () => {
        props.setOpen(false); 
        setActiveStep(0);
    }

    return (
        <Modal
            isOpen={props.open}
            contentLabel="Upload Card"
            onRequestClose={() => onModalClose()}
            id='modal'>
            <div id='formHeader'>
                <img id='formIcon' src={PictureIcon} alt="card-icon" />
                <h1>New Card Form</h1>
            </div>
            <div id='slides'>
                <form onSubmit={handleSubmit}>
                    <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                        <NameAndFile title={title} handleTitleChange={handleTitleChange} file={file} handleFileChange={handleFileChange} />
                        <Time startDate={startDate} handleStartDateChange={setStartDate} endDate={endDate} handleEndDateChange={setEndDate} />
                        <Preview title={title} file={file} startDate={startDate} endDate={endDate} handleSubmit={handleSubmit} />
                    </SwipeableViews>
                </form>
            </div>
            <div id='cardFooter'>
                <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
                            Next
                    </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            Back
                    </Button>
                    }
                />
            </div>
        </Modal>

    )
}

export default CardForm;