import React, { Image } from 'react';   

import './previewSlide.css';

const generatePreviewImage = (image) => {
    // First check if there was an image selected yet
    if (image) return (<img id='previewImage' src={URL.createObjectURL(image)} />);
    return (<h4>No image selected</h4>)
}

const Preview = (props) => {

    return (
        <div>
            <h1>Preview</h1>
            <h3>{props.title}</h3>
            {generatePreviewImage(props.file)}
            <h3>{props.startDate.toString()}</h3>
            <h3>{props.endDate.toString()}</h3>
            <input type='submit' value='Submit'/>
        </div>
    )
}

export default Preview;