import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import firebase from '../../Authentication/Firebase';

import './style.css';
import Hamburger from '../../assets/icons/hamburger.svg';

const logout = (history) => {
    firebase.auth().signOut().then(() => {
        history.push('/login');
    }).catch((err) => {
        alert('already signed out');
    });
}

const NavBar = (props) => {

    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);


    const toggleDrawer = (open) => (e) => {
        if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift'))
            return;
        setIsOpen(open);
    }

    return (
        <div id='navContainer'>
            {/* Header of page */}
            <img id='menuIcon' src={Hamburger} onClick={toggleDrawer(true)} />
            <h1 id='navTitle'>LCD MANAGER</h1>
            <img id='spacer' src={Hamburger} />
            
            {/* Drawer Menu */}
            <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
                <div id='menuList'>
                    <List>
                        <ListItem button onClick={() => history.push('/screen')}>
                            <ListItemText primary="View Screen" />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/account')}>
                            <ListItemText primary="Account" />
                        </ListItem>
                        <ListItem button onClick={() => logout(history)}>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

export default NavBar;

