import React from 'react';
import axios from 'axios';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import MediaItem from '../Card';
import * as endpoints from '../../../constants/endpoints';

const reOrder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

const getListStyle = (isDraggingOver) => {
    return {
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: 8,
        width: 250
    }
}

const updateList = (newList) => {
    axios.put(endpoints.REPLACE_CARDS('live'), {cards: newList})
    .then(res => {
        console.log(res);
    })
    .catch(err => {
        console.log(err);
    });
}


const CardColumn = (props) => {

    const onDragEnd = (result) => {
        // If item is dropped outside the list
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;
        const items = reOrder(props.items, result.source.index, result.destination.index);
        props.setItems(items);
        updateList(items);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={props.id}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        {props.items.map((item, index) => (<MediaItem key={item._id} index={index} item={item}/>))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default CardColumn;