// Firebase app is required
import * as firebase from 'firebase/app';

// Adding firebase services we want to use (only using Firebase Auth)
import 'firebase/auth';

// The app's Firebase project configurations
const firebaseConfig = {
    apiKey: "AIzaSyBZWkaqEKC9O4EA3IQ-hFfxY4AudcOAmVs",
    authDomain: "lcd-manager-e83f0.firebaseapp.com",
    databaseURL: "https://lcd-manager-e83f0.firebaseio.com",
    projectId: "lcd-manager-e83f0",
    storageBucket: "lcd-manager-e83f0.appspot.com",
    messagingSenderId: "221917919697",
    appId: "1:221917919697:web:52a6c780e863de6c1c48f0",
    measurementId: "G-EX7KC19ZHS"
};

// Initialize firebase
const Firebase = firebase.initializeApp(firebaseConfig);

export default Firebase;