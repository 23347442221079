import React, { useState } from 'react';

import './style.css';
import LockIcon from '../../assets/icons/lock.svg';

import firebase from '../../Authentication/Firebase';

const ResetPasswordPage = () => {

    const [email, setEmail] = useState("");

    let handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    let handleSubmit = (e) => {
        firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            alert("The reset link has been sent to your email.");
        })
        .catch((err) => {
            alert(err);
        });
    }

    return (
        <div id='forgotPasswordContainer'>
            <img id='lockIcon' src={LockIcon} alt='lock-icon'/>
            <h1 id='forgotPasswordTitle'>Forgot your password?</h1>
            <p id='description'>Enter your email and we will send you a reset link.</p>
            <form id='forgotPasswordForm' onSubmit={handleSubmit}>
                <input className='formField' type="text" placeholder='Email' value={email} onChange={handleEmailChange} />
                <input className='formButton' type='submit' value='Send Request' />
            </form>
        </div>
    );

}

export default ResetPasswordPage;