import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './pages/darkTheme.css';

// Pages
import LoginPage from './pages/LoginPage';
import ManagerPage from './pages/ManagerPage';
import AccountPage from './pages/account/AccountPage';
import ScreenPage from './pages/screen/ScreenPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/reset-password">
          <ResetPasswordPage />
        </Route>
        <Route path="/manager">
          <ManagerPage />
        </Route>
        <Route path="/account">
          <AccountPage />
        </Route>
        <Route path="/screen">
          <ScreenPage />
        </Route>
        <Redirect from="/" to="/login"/>
      </Switch>
    </Router>
  );
}

export default App;
