import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import firebase from '../../Authentication/Firebase';
import Modal from 'react-modal';

import './style.css';

import Navbar from '../../components/NavBar';
import CardForm from '../../components/CardForm';
import CardColumn from '../../components/DnD/CardColumn';
import * as endpoints from '../../constants/endpoints';


const ManagerPage = () => {

    const history = useHistory();
    const [live, setLive] = useState([{}]);
    const [isFormOpen, setIsFormOpen] = useState(false);

    const setItems = (items) => {
        console.log(items);
        setLive(items)
    }

    useEffect(() => {
        // On mount, check to see if the user has been authenticated
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                // User is not authenticated redirect to forbidden page
                history.push('/login');
            }
            // Get all media
            axios.get(endpoints.GET_CARDS("live"))
                .then(res => {
                    setLive(res.data.data.cards);
                })
                .catch(err => {
                    console.log(err);
                });
        });
    }, []);

    return (
        <div>
            <Navbar />
            <div>
                <button>Live</button>
                <button>Upcoming</button>
                <button>Expired</button>
                <button>All</button>
                <button onClick={() => setIsFormOpen(true)}>+</button>
                <CardForm open={isFormOpen} setOpen={setIsFormOpen}/>
                <CardColumn items={live} setItems={setItems} id={"live"} />
            </div>

        </div>
    );
}

export default ManagerPage;