import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => {
    return {
        userSelect: "none",
        padding: 8*2,
        margin: "0 0 8px 0",
        background: isDragging ? "lightgreen" : "grey",
        ...draggableStyle
    }
}

const MediaItem = (props) => {
    return (
        <Draggable key={props.item._id} draggableId={props.item._id} index={props.index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} 
                {...provided.draggableProps} 
                {...provided.dragHandleProps} 
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    {props.item.title}
                </div>
            )}
        </Draggable>
    );
}

export default MediaItem;