import React, { useState, useEffect } from 'react';
import LCDScreen from '../../assets/icons/lcd screen.png';
import EmailIcon from '../../assets/icons/email.svg';

import './style.css';
// import '../cardStyle.css';

import firebase from '../../Authentication/Firebase';
import { Link, useHistory } from 'react-router-dom';

const LoginPage = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    let handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    let handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    let handleSubmit = (e) => {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                history.push('/manager');
            })
            .catch((err) => {
                // let errorCode = err.code;
                let errorMessage = err.message;
                alert(errorMessage);
            });
        e.preventDefault();
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is already authenticated
                history.push('/manager');
            }
        });
    });

    return (
        <div id='loginModal'>
            <div id='loginHeader'>
                <img id='lcdIcon' src={LCDScreen} alt="lcd icon" />
                <h1 id='title'>LCD MANAGER</h1>
            </div>
            <form id='loginForm' onSubmit={handleSubmit}>
                <input className='formField' type="text" placeholder='Email' value={email} onChange={handleEmailChange} />
                <input className='formField' type="password" placeholder='Password' value={password} onChange={handlePasswordChange} />
                <input className='formButton' type='submit' value='Login' />
                <Link className='formLink' to='/reset-password'>Forgot your password?</Link>
            </form>
        </div>
    );
}

export default LoginPage;